<template>
  <div>
    <v-text-field
      prepend-icon="mdi-map-marker"
      v-model="address.firstLine"
      label="Ligne 1 d'adresse"
      required
    ></v-text-field>
    <v-text-field
      prepend-icon="-"
      v-model="address.secondLine"
      label="Ligne 2 d'adresse"
    ></v-text-field>
    <v-text-field
      prepend-icon="-"
      v-model="address.postalCode"
      label="Code postal"
      required
    ></v-text-field>
    <v-text-field
      prepend-icon="-"
      v-model="address.city"
      label="Ville"
      required
    ></v-text-field>
  </div>
</template>

<script>
export default {
  data: () => ({
    address: {
      firstLine: '',
      secondLine: '',
      postalCode: '',
      city: ''
    }
  }),
  methods: {
    setAddress (address) {
      this.address = address
    },
    getAddress () {
      return this.address
    }
  }
}
</script>
