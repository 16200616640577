<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="company.name"
          prepend-icon="mdi-label"
          required
          label="Nom de l'entreprise"
        />
        <v-select
          :items="types"
          v-model="company.type"
          prepend-icon=" "
          item-text="name"
          item-value="id"
          required
          label="Type de l'entreprise"
        />
        <v-text-field
          v-model="company.domain"
          prepend-icon="mdi-web"
          label="Domaine (email.com)"
        />
        <AddressForm ref="address"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close">Annuler</v-btn>
        <v-btn
          color="success" text
          :loading="loading"
          @click="save">
          {{ formAction }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rfdc from 'rfdc'
import AddressForm from "../AddressForm.vue"
import { availableCompanyTypes } from '@/utils/constants'

export default {
  components: {
    AddressForm
  },
  computed: {
    formTitle() {
      return this.mode === 'create' ? 'Nouvelle entreprise' : "Modification d'une entreprise"
    },
    formAction() {
      return this.mode === 'create' ? "Créer l'entreprise" : 'Enregistrer'
    },
  },
  data() {
    return {
      mode: 'create',
      loading: false,
      dialog: false,
      company: {},
      types: availableCompanyTypes
    }
  },
  created () {
    this.clearForm()
  },
  methods: {
    open(item = null, defaultType = null) {
      let oldMode = this.mode
      this.mode = item ? 'edit' : 'create'
      if (oldMode === 'edit' && this.mode === 'create') {
        this.clearForm()
      }
      if (this.mode === 'edit') {
        this.company = rfdc()(item)
      }

      // set a default type (in any mode)
      console.log(defaultType)
      if (defaultType) {
        let typeFound = availableCompanyTypes.find(t => t.key === defaultType)
        if (!typeFound) {
          typeFound = availableCompanyTypes[0]
        }
        this.company.type = typeFound.id
      }

      this.dialog = true
      this.$nextTick(() => {
        if (
          this.company.address != null &&
          this.company.address !== [] &&
          !Array.isArray(this.company.address)
        ) {
          this.$refs.address.setAddress(this.company.address)
        }
      })
    },
    clearForm() {
      this.company = {
        address: {
          firstLine: '',
          secondLine: '',
          postalCode: '',
          city: ''
        },
        name: '',
        domain: '',
        type: 'FINAL_CLIENT'
      }
    },
    close() {
      this.dialog = false
    },
    save() {
      this.loading = true
      let company = rfdc()(this.company)
      company.address = this.$refs.address.getAddress()
      if (this.mode === 'create') {
        this.$store.dispatch('companies/create', company).then(newCompanyId => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'success',
            text: "L'entreprise a bien été créée"
          })
          this.$emit('finished', { ...company, id: newCompanyId })
          this.close()
          this.clearForm()
        }).catch(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la création de l'entreprise"
          })
        })
      } else {
        this.$store.dispatch('companies/update', company).then(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'success',
            text: "L'entreprise a bien été modifiée"
          })
          this.close()
          this.$emit('finished', company)
        }).catch(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la modification de l'entreprise"
          })
        })
      }
    }
  }
}
</script>
