<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <v-icon left>mdi-search</v-icon>
        Recherche d'une entreprise
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="5">
            <v-select
              label="Par"
              v-model="field"
              item-text="label"
              item-value="id"
              :items="availableFields"
            />
          </v-col>
          <v-col cols="12" sm="7">
            <v-text-field
              v-model="query"
              label="Recherche"
            />
          </v-col>
        </v-row>
        <v-checkbox
          v-if="companyType"
          style="margin-top: 0"
          hide-details
          :label="`Rechercher uniquement les entreprises du type '${companyTypeDetails.name}'`"
          v-model="filterByType"
        />
        <v-progress-linear
          indeterminate
          :style="{ opacity: loading ? 1 : 0 }"
        />
        <v-layout
          v-if="!loading && !enableResults && suggestions.length === 0"
          justify-center
        >
          <div>
            Veuillez sélectionnez un champ et rentrez une valeur pour effectuer une recherche.
          </div>
        </v-layout>
        <v-layout
          v-if="!loading && enableResults && noResults"
          justify-center
        >
          <div>
            Aucune entreprises
          </div>
        </v-layout>
        <v-list v-if="(enableResults && companies.length > 0) || (!enableResults && suggestions.length > 0)">
          <v-subheader v-if="!enableResults">
            <span>Suggestion d'entreprises</span>
          </v-subheader>
          <v-divider />
          <template
            v-for="company in (enableResults ? companies : suggestions)">
            <v-list-item
              :key="company.id"
              ripple
              @click="selectCompany(company)"
            >
              <v-list-item-avatar>
                <v-icon v-if="selectedCompany && selectedCompany.id === company.id">
                  mdi-checkbox-marked
                </v-icon>
                <v-icon v-else>mdi-domain</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ company.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="'divider__' + company.id"/>
          </template>
        </v-list>
        <div v-else class="pb-4 pt-4">
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="close">
          Annuler
        </v-btn>
        <v-btn
          text
          color="secondary"
          :disabled="!selectedCompany"
          @click="submit"
        >
          Sélectionner
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { debounce } from 'lodash'
import { availableCompanyTypes } from '../../utils/constants'

export default {
  props: {
    companyType: {
      type: String,
      default: null
    }
  },
  data: () => ({
    dialog: false,
    field: 'name',
    query: '',
    companies: [],
    suggestions: [],
    loading: false,
    selectedCompany: null,
    filterByType: false,
    noResults: false,
    availableFields: [
      { id: 'id', label: 'Identifiant'},
      { id: 'name', label: 'Nom'}
    ]
  }),
  watch: {
    query () {
      this.noResults = false
      debounce(this.fetchData, 500)()
    },
    field () {
      this.fetchData()
    },
    filterByType () {
      if (this.enableResults) {
        this.fetchData()
      } else {
        this.fetchSuggestions()
      }
    }
  },
  computed: {
    enableResults () {
      return !(this.field === '' || this.query === '')
    },
    companyTypeDetails () {
      if (this.companyType) {
        let res = availableCompanyTypes.find(t => t.key == this.companyType)
        if (res) {
          return res
        }
      }
      return {}
    }
  },
  methods: {
    open() {
      this.suggestions = []
      this.noResults = false
      this.dialog = true
      if (this.companyType) {
        this.filterByType = true
      }
      this.$nextTick(() => {
        this.fetchSuggestions()
      })
    },
    close() {
      this.dialog = false
    },
    reset() {
      this.selectedCompany = null
      this.query = ''
      this.companies = []
    },
    fetchSuggestions() {
      this.loading = true
      let additionalParams = {
        itemsPerPage: 5,
        'order[createdAt]': true
      }
      if (this.filterByType) {
        additionalParams['type'] = this.companyTypeDetails.id
      }
      this.$store.dispatch('companies/fetchAll', {
        additionalParams
      }).then(res => {
        this.loading = false
        this.suggestions = res.member
      })
    },
    fetchData() {
      if (!this.enableResults) {
        this.companies = []
        return
      }
      this.selectedCompany = null
      let additionalParams = { itemsPerPage: 5 }
      additionalParams[this.field] = this.query
      console.log('filter by type', this.filterByType, this.companyTypeDetails.id)
      if (this.filterByType) {
        additionalParams['type'] = this.companyTypeDetails.id
      }
      this.loading = true
      this.$store.dispatch('companies/fetchAll', {
        additionalParams
      }).then(res => {
        this.loading = false
        this.companies = res.member
        if (this.companies.length === 0) {
          this.noResults = true
        }
      })
    },
    selectCompany(company) {
      if (company === this.selectedCompany) {
        this.selectedCompany = null
      } else {
        this.selectedCompany = company
      }
    },
    submit () {
      this.$emit('finished', this.selectedCompany)
      this.close()
    }
  }
}
</script>
