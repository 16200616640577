<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <v-icon left>mdi-account-search</v-icon>
        Recherche d'un utilisateur
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="5">
            <v-select
              label="Par"
              v-model="field"
              item-text="label"
              item-value="id"
              :items="availableFields"
            />
          </v-col>
          <v-col cols="12" sm="7">
            <v-text-field
              v-model="query"
              label="Recherche"
            />
          </v-col>
        </v-row>
        <v-checkbox
          v-if="company"
          style="margin-top: 0"
          hide-details
          :label="`Rechercher uniquement dans l'entreprise '${company.name}'`"
          v-model="filterByCompany"
        />
<!--        <div v-if="!loading && !enableResults">-->
<!--          <v-layout justify-center>-->
<!--            <div>-->
<!--              Veuillez sélectionnez un champ et rentrez une valeur pour effectuer une recherche.-->
<!--            </div>-->
<!--          </v-layout>-->
<!--        </div>-->
        <v-progress-linear
          indeterminate
          class="mt-2"
          :style="{ opacity: loading ? 1 : 0 }"
        />
        <v-layout
          v-if="!loading && query.length === 0 && !enableResults && suggestions.length === 0"
          class="mt-8" justify-center
        >
          <div>Aucun utilisateurs à suggérer</div>
        </v-layout>
        <v-layout
          v-if="!loading && enableResults && noResults"
          class="mt-8" justify-center
        >
          <div>Aucun utilisateurs suite à la recherche </div>
        </v-layout>
        <v-list v-if="(enableResults && users.length > 0) || (!enableResults && suggestions.length > 0)">
          <v-subheader v-if="!enableResults">
            <span>Suggestion d'utilisateurs</span>
          </v-subheader>
          <v-divider />
          <template
            v-for="user in (enableResults ? users : suggestions)">
            <v-list-item
              :key="user.id"
              ripple
              @click="selectUser(user)"
            >
              <v-list-item-avatar>
                <v-icon v-if="selectedUser && selectedUser.id === user.id">
                  mdi-checkbox-marked
                </v-icon>
                <v-icon v-else>mdi-account-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ user.fullName }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="user.fullName != user.email">
                  {{ user.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="'divider__' + user.id" />
          </template>
        </v-list>
        <div v-else class="pb-4 pt-4">
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="close">
          Annuler
        </v-btn>
        <v-btn
          text
          color="secondary"
          :disabled="!selectedUser"
          @click="submit"
        >
          Sélectionner
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { debounce } from 'lodash'

export default {
  props: {
    closeOnSubmit: {
      type: Boolean,
      default: true,
    },
    company: {
      type: Object,
      default: null,
    }
  },
  data: () => ({
    dialog: false,
    field: 'email',
    query: '',
    users: [],
    suggestions: [],
    loading: false,
    selectedUser: null,
    filterByCompany: false,
    noResults: false,
    availableFields: [
      { id: 'id', label: 'Identifiant'},
      { id: 'email', label: 'Adresse e-mail'},
      { id: 'name', label: 'Nom'}
    ]
  }),
  watch: {
    query () {
      this.noResults = false
      debounce(this.fetchData, 500)()
    },
    filterByCompany () {
      if (this.enableResults) {
        this.fetchData()
      } else {
        this.fetchSuggestions()
      }
    },
    field () {
      this.fetchData()
    }
  },
  computed: {
    enableResults () {
      return !(this.field === '' || this.query === '')
    },
  },
  methods: {
    open() {
      this.suggestions = []
      this.dialog = true
      this.noResults = false
      if (this.company) {
        this.filterByCompany = true
      }
      this.fetchSuggestions()
    },
    close() {
      this.dialog = false
    },
    reset() {
      this.selectedUser = null
      this.query = ''
      this.users = []
    },
    fetchSuggestions() {
      this.loading = true
      let additionalParams = {
        itemsPerPage: 5,
        'order[createdAt]': true
      }
      if (this.company && this.filterByCompany) {
        additionalParams['company'] = this.company.id
      }
      this.$store.dispatch('users/fetchAll', {
        additionalParams
      }).then(res => {
        this.loading = false
        this.suggestions = res.member
      })
    },
    fetchData() {
      if (!this.enableResults) {
        this.users = []
        return
      }
      this.loading = true
      this.selectedUser = null
      let additionalParams = { itemsPerPage: 5 }
      if (this.field === 'name') {
        additionalParams['firstName'] = this.query
        additionalParams['lastName'] = this.query
      } else {
        additionalParams[this.field] = this.query
      }
      if (this.company && this.filterByCompany) {
        additionalParams['company'] = this.company.id
      }
      this.$store.dispatch('users/fetchAll', {
        additionalParams
      }).then(res => {
        this.loading = false
        this.users = res.member
        if (this.users.length === 0) {
          this.noResults = true
        }
      })
    },
    selectUser(user) {
      if (user === this.selectedUser) {
        this.selectedUser = null
      } else {
        this.selectedUser = user
      }
    },
    submit () {
      this.$emit('finished', this.selectedUser)
      if (this.closeOnSubmit) {
        this.close()
      }
    }
  }
}
</script>
