<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="sites"
      :loading="tableLoading"
      :options.sync="options"
      :server-items-length="totalCount"
      @dblclick:row="onClickedRow"
    >
      <template v-slot:top>
        <v-toolbar flat color="transparent">
          <v-layout
            style="max-width: 300px">
            <v-text-field
              ref="searchField"
              v-model="query"
              hide-details
              label="Recherche"
              clearable
              clear-icon="mdi-close"
              @click:clear="query = ''"
            />
          </v-layout>
          <v-spacer />
          <v-btn
            outlined small icon dark
            color="primary" class="mb-2 mr-2"
            @click="fetchData()"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
          <!-- <v-btn
            v-if="$store.state.auth.user.isAdmin && !isLinkedToCompany"
            outlined small icon dark
            color="primary" class="mb-2 mr-2"
            @click="toggleSearch"
          >
            <v-icon small>mdi-magnify</v-icon>
          </v-btn> -->
          <v-btn
            v-if="$store.state.auth.user.isAdmin"
            dark color="accent" class="mb-2 ml-2"
            @click="$refs.editForm.open()"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $vuetify.breakpoint.smAndDown ? 'Ajouter' : 'Ajouter un chantier' }}
          </v-btn>
        </v-toolbar>
      </template>
      <!-- <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAtObject | formatDate }}
      </template> -->
      <template v-slot:[`item.wasteReceptionDate`]="{ item }">
        {{ item.wasteReceptionDate | formatDate('date') }}
      </template>
      <template v-slot:[`item.address`]="{ item }">
        {{ item.address | formatAddress | truncate }}
      </template>
      <!-- <template v-slot:[`item.company.name`]="{ item }">
        <span v-if="item.company">
          <router-link :to="{ name: 'CompanySites', params: { id: item.company.id } }">
            {{ item.company.name }}
          </router-link>
        </span>
        <span v-else>
          Aucune
        </span>
      </template> -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="secondary"
            >
              <v-icon
                small
                @click="openItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <span>Voir</span>
        </v-tooltip>
        <v-tooltip
          v-if="$store.state.auth.user.isAdmin"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="info"
            >
              <v-icon
                small
                @click="$refs.editForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>
        <v-tooltip
          v-if="$store.state.auth.user.isAdmin"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon small color="error"
            >
              <v-icon
                small
                @click="$refs.removeForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <EditSite
      ref="editForm"
      :default-company="company"
      @finished="fetchData"
    />
    <RemoveItem
      resource="sites"
      title="ce site"
      ref="removeForm"
      @finished="fetchData"
    />
  </div>
</template>

<script>
import EditSite from "./EditSite.vue"
import RemoveItem from "../RemoveItem.vue"

import { debounce } from 'lodash'

export default {
  components: { EditSite, RemoveItem },
  props: {
    // to filter the list of sites by the company they belong to
    company: {
      type: Object,
      default: null,
    },
    isLinkedToCompany: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      enableSearch: false,
      query: '',
      preventDefault: false,
      tableLoading: false,
      sites: [],
      options: {},
      totalCount: 0,
      headers: [
        ...(this.$store.state.debugMode ? [{
          text: 'Id',
          value: 'id'
        }] : []),
        {
          text: 'Nom du chantier',
          value: 'name'
        },
        {
          text: 'Adresse',
          value: 'address'
        },
        // ...(this.company ? [] : [{
        //   text: 'Entreprise',
        //   value: 'company.name'
        // }]),
        {
          text: 'Date de reception',
          value: 'wasteReceptionDate'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    query: debounce(function(_) {
      this.fetchData()
    }, 500),
    options: {
      handler () {
        if (this.isLinkedToCompany && !this.company.id) {
          return
        }
        this.fetchData()
      },
      deep: true,
    },
    company: {
      handler () {
        this.fetchData()
      },
      deep: true,
    }
  },
  methods: {
    fetchData () {
      let additionalParams = {}
      if (this.query.length > 0) {
        [
          'name',
          'address.firstLine',
          'address.secondLine',
          'address.postalCode',
          'address.city'
        ].forEach(key => {
          additionalParams[key] = this.query
        })
      }
      if (this.company) {
        additionalParams.finalClient = this.company.id
        additionalParams.ascensorist = this.company.id
        additionalParams.amo = this.company.id
      }
      this.tableLoading = true
      this.$store.dispatch(
        'sites/fetchAll',
        {...this.options, additionalParams }
      ).then(res => {
        this.tableLoading = false
        this.sites = res.member
        this.totalCount = res.totalItems
      })
    },
    onClickedRow (_, { item }) {
      this.openItem(item)
    },
    openItem (item) {
      if (!item.id) {
        throw 'Cannot open site, no id found'
      }
      this.$router.push({ name: 'SiteElevators', params: { id: item.id } })
    },
    toggleSearch () {
      this.enableSearch = !this.enableSearch
      if (this.enableSearch) {
        this.$nextTick(() => {
          this.$refs.searchField.$el.focus()
        })
      } else {
        this.query = ''
      }
    }
  }
}
</script>
