<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="internal"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="internal"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="internal"
      no-title
      scrollable
      color="primary"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        Annuler
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(internal)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    }
  },
  data: () => ({
    menu: false,
    internal: ''
  }),
  mounted () {
    this.internal = this.date
  },
  watch: {
    internal (val) {
      this.$emit('update:date', val)
    },
    date (val) {
      this.internal = val
    }
  },
}
</script>
