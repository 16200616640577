<template>
  <ListSites
    :company="company"
    is-linked-to-company
  />
</template>

<script>
import ListSites from '../../components/Sites/ListSites.vue'

export default {
  props: {
    company: {
      type: Object,
      default: null,
    },
  },
  components: { ListSites }
}
</script>
