<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-text-field
          prepend-icon="mdi-at"
          v-model="user.email"
          required
          :disabled="$store.state.auth.user.id === user.id"
          type="email"
          label="E-mail"/>
        <!--        <v-text-field-->
        <!--            prepend-icon="mdi-key"-->
        <!--            v-model="user.newPassword"-->
        <!--            type="password"-->
        <!--            label="Nouveau mot de passe"/>-->
        <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
          <v-col cols="12" md="6">
            <v-text-field
              prepend-icon="mdi-label"
              v-model="user.firstName"
              type="text"
              label="Prénom"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :prepend-icon="$vuetify.breakpoint.smAndDown ? ' ' : ''"
              v-model="user.lastName"
              type="text"
              label="Nom"/>
          </v-col>
        </v-row>
        <v-text-field
          prepend-icon="mdi-phone"
          v-model="user.phone"
          type="text"
          label="Numéro de téléphone"
        />
        <v-select
          prepend-icon="mdi-account-circle"
          v-model="user.roles"
          :items="roles"
          :disabled="$store.state.auth.user.id === user.id"
          multiple
          label="Rôle"
          item-text="name"
          item-value="id"
        />
        <v-text-field
          v-model="companyName"
          readonly outlined
          label="Entreprise"
          :disabled="companyDisabled"
          @click="$refs.selectCompany.open()"
          @keydown.enter="$refs.selectCompany.open()"
        >
          <template v-slot:prepend>
            <div class="d-flex align-center">
              <v-icon>mdi-domain</v-icon>
            </div>
          </template>

          <template v-slot:append>
            <div style="margin-top: -3px">
              <v-btn
                v-if="company.id"
                text small icon
                class="mr-1"
                color="info"
                :disabled="companyDisabled"
                @click="$refs.editCompany.open(company)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="!company.id"
                text small icon
                color="success"
                :disabled="companyDisabled"
                @click="$refs.editCompany.open()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                v-if="company.id"
                text small icon
                color="error"
                :disabled="companyDisabled"
                @click="removeCompany"
              >
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
            </div>
          </template>
        </v-text-field>
        <v-switch
          v-model="user.sendInviteEmail"
          label="Envoyer un e-mail d'invitation"
          :disabled="$store.state.auth.user.id === user.id"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="close">Annuler</v-btn>
        <v-btn
          color="success" text
          :loading="loading"
          @click="save">
          {{ formAction }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <SelectCompany
      ref="selectCompany"
      @finished="onCompanySelected"
    />
    <EditCompany
      ref="editCompany"
      @finished="onCompanyEdited"
    />
  </v-dialog>
</template>

<script>
import rolesUtils from "../../utils/roles"
import { randomString } from "@/utils/random"
import SelectCompany from '../Companies/SelectCompany'
import EditCompany from '../Companies/EditCompany'
import rfdc from 'rfdc'

export default {
  components: { SelectCompany, EditCompany },
  props: {
    defaultCompany: {
      type: Object,
      default: null
    },
    isLinkedToCompany: {
      // will disallow user to manually set the company, will always take the defaultCompany for granted
      type: Boolean,
      default: false
    }
  },
  computed: {
    formTitle() {
      return this.mode === 'create' ? 'Nouveau utilisateur' : "Modification d'un utilisateur"
    },
    formAction() {
      return this.mode === 'create' ? "Créer l'utilisateur" : 'Enregistrer'
    },
    companyName () {
      if (!this.company || !this.company.id) {
        return ''
      }
      return this.company.name
    },
    companyDisabled () {
      return this.defaultCompany != null || this.isLinkedToCompany
    }
  },
  data: () => ({
    mode: 'create',
    loading: false,
    dialog: false,
    company: { id: null },
    user: {},
    companies: [],
    roles: rolesUtils.getRoles()
  }),
  created() {
    this.clearForm()
  },
  methods: {
    clearForm() {
      this.user = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        roles: [],
        company: { id: null }
      }
    },
    open(item = null) {
      let oldMode = this.mode
      this.mode = item ? 'edit' : 'create'
      if (oldMode === 'edit' && this.mode === 'create') {
        this.clearForm()
      }
      if (this.mode == 'edit') {
        this.user = rfdc()(item)
      }
      // auto select if default company
      if (this.defaultCompany) {
        this.company = this.defaultCompany
      }
      if (this.isLinkedToCompany && !this.defaultCompany) {
        this.company = { id: null }
      }
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    save() {
      this.loading = true
      let user = rfdc()(this.user)
      if (this.company.id) {
        user.company = '/api/companies/' + this.company.id
      }

      if (this.company.id === null) {
        user.company = null
      }

      if (this.mode === 'create') {
        if (!user.email) {
          user.email = randomString() + '@anonymous.local'
        }
        this.$store.dispatch('users/create', user).then(userId => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'success',
            text: "L'utilisateur a bien été créé"
          })
          if (user.email && user.sendInviteEmail) {
            this.$apitator.post(
              '/invite/request',
              { email: user.email },
              { withAuth: true }
            ).then(() => {
              this.$store.commit('alert/add', {
                color: 'success',
                text: "Un e-mail d'invitation va être envoyé à l'utilisateur"
              })
            }).catch(err => {
              this.$store.commit('alert/add', {
                color: 'error',
                text: "L'email d'invitation n'a pas pu être envoyé"
              })
            })
          }
          user.id = userId
          this.$emit('finished', user)
          this.close()
          this.clearForm()
        }).catch(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la création de l'utilisateur"
          })
        })
      } else {
        this.$store.dispatch('users/update', user).then(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'success',
            text: "L'utilisateur a bien été modifié"
          })
          this.close()
          this.$emit('finished', user)
        }).catch(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la modification de l'utilisateur"
          })
        })
      }
    },

    openCompanySelect() { this.$refs.selectCompany.open() },
    removeCompany() {
      this.company = { id: null }
    },
    onCompanySelected(company) {
      this.company = company
      this.$refs.selectCompany.reset()
    },
    onCompanyEdited(company) {
      this.company = company
      this.$forceUpdate()
    },
  }
}
</script>
