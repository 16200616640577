<template>
  <div>
    <v-dialog v-model="dialog" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                prepend-icon="mdi-label"
                v-model="site.name"
                label="Nom du chantier (optionel)">
              </v-text-field>
              <MenuDatePicker
                label="Date de réception des déchets"
                v-bind:date.sync="site.wasteReceptionDate"
                ref="date-picker"/>
              <AddressForm ref="address" />
            </v-col>
            
            <v-col cols="12" md="6">
              <SiteCompanyForm
                ref="finalClient"
                type="finalClient" required />
              <SiteCompanyForm
                ref="ascensorist"
                type="ascensorist" required />
              <SiteCompanyForm
                ref="amo"
                type="amo" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="close">Annuler</v-btn>
          <v-btn
            color="success" text
            :loading="loading"
            @click="save">
            {{ formAction }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddressForm from '../AddressForm.vue'
import MenuDatePicker from '../MenuDatePicker.vue'
import {DateTime} from 'luxon'
import rfdc from "rfdc"
import SiteCompanyForm from './SiteCompanyForm.vue'

const defaultSite = {
  name: '',
  wasteReceptionDate: '',
  contact: { id: null },
  address: {
    firstLine: '',
    secondLine: '',
    postalCode: '',
    city: ''
  }
}

export default {
  components: {
    SiteCompanyForm,
    AddressForm, MenuDatePicker
  },
  props: {
    defaultCompany: {
      type: Object,
      default: null,
    },
  },
  computed: {
    formTitle() {
      return this.mode === 'create' ? 'Nouveau chantier' : "Modification d'un chantier"
    },
    formAction() {
      return this.mode === 'create' ? 'Créer le chantier' : 'Enregistrer'
    },
  },
  data: () => ({
    mode: 'create',
    loading: false,
    dialog: false,
    site: defaultSite
  }),
  created() {
  },
  methods: {
    clearForm() {
      this.site = defaultSite
    },
    open(item = null) {
      let oldMode = this.mode
      this.mode = item ? 'edit' : 'create'
      if (oldMode === 'edit' && this.mode === 'create') {
        this.clearForm()
      }
      if (this.mode === 'edit') {
        this.site = rfdc()(item)
      }
      this.site = { ...defaultSite, ...this.site }


      this.dialog = true

      // load the wasteReceptionDate in the dd-mm-yyyy format
      if (this.site.wasteReceptionDate) {
        this.site.wasteReceptionDate = DateTime.fromISO(this.site.wasteReceptionDate).toISODate()
      }

      this.$nextTick(() => {
        if (
          this.site.address != null &&
          this.site.address !== [] &&
          !Array.isArray(this.site.address)
        ) {
          this.$refs.address.setAddress(this.site.address)
        }

        // TODO: auto select company based on the type

        this.$refs.finalClient.load(this.site)
        this.$refs.ascensorist.load(this.site)
        this.$refs.amo.load(this.site)
      })
    },
    close() { this.dialog = false },
    
    save() {
      this.loading = true

      let site = rfdc()(this.site)

      console.debug(JSON.parse(JSON.stringify(site)))

      // TODO: add finalClient, ascensorist, amo with ids
      // if (!site.company.id) {
      //   site.company = null
      // } else if (site.company.id) {
      //   site.company = '/api/companies/' + site.company.id
      // }
      site = this.$refs.finalClient.contributeToFormData(site)
      site = this.$refs.ascensorist.contributeToFormData(site)
      site = this.$refs.amo.contributeToFormData(site)

      if (!site.wasteReceptionDate) {
        delete site.wasteReceptionDate
      }

      site.address = this.$refs.address.getAddress()

      if (this.mode === 'create') {
        this.$store.dispatch('sites/create', site).then(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'success',
            text: "Le chantier a bien été créée"
          })
          this.close()
          this.$emit('finished')
          this.clearForm()
        }).catch(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la création du chantier"
          })
        })
      } else {
        this.$store.dispatch('sites/update', site).then(() => {
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'success',
            text: "Le chantier a bien été modifié"
          })
          this.close()
          this.$emit('finished')
        }).catch(err => {
          console.error(err)
          this.loading = false
          this.$store.commit('alert/add', {
            color: 'error',
            text: "Une erreur est survenue lors de la modification du chantier"
          })
        })
      }
    }
  }
}
</script>
