<template>
  <div>
    <v-text-field
      v-model="companyName"
      readonly outlined
      ref="companyTextField"
      :label="companyFormLabel"
      @click="$refs.selectCompany.open()"
      @keydown.enter="$refs.selectCompany.open()"
    >
      <template v-slot:prepend>
        <div class="d-flex align-center">
          <v-icon>mdi-domain</v-icon>
        </div>
      </template>

      <template v-slot:append>
        <div
          :style="'margin-top: -3px;' + (company.id ? 'min-width: 60px' : '')"
        >
          <v-btn
            v-if="company.id"
            text small icon
            class="mr-1"
            color="info"
            @click="openEditCompany(company)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="!company.id"
            text small icon
            color="success"
            @click="openEditCompany(null)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            v-if="company.id"
            text small icon
            color="error"
            @click="removeCompany"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-text-field>

    <v-text-field
      v-model="contactName"
      readonly outlined
      ref="contactTextField"
      :label="contactFormLabel"
      @click="$refs.selectUser.open()"
      @keydown.enter="$refs.selectUser.open()"
    >
      <template v-slot:prepend>
        <div class="d-flex align-center">
          <v-icon>
            mdi-account-circle
          </v-icon>
        </div>
      </template>

      <template v-slot:append>
        <div
          :style="'margin-top: -3px;' + (contact.id ? 'min-width: 60px' : '')"
        >
          <v-btn
            v-if="contact.id"
            text small icon
            class="mr-1"
            color="info"
            @click="openEditUser(contact)"
          >
            <v-icon>mdi-account-edit</v-icon>
          </v-btn>
          <v-btn
            v-if="!contact.id"
            text small icon
            color="success"
            @click="openEditUser(null)"
          >
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
          <v-btn
            v-if="contact.id"
            text small icon
            color="error"
            @click="removeContact"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-text-field>

    <SelectUser
      ref="selectUser"
      :company="defaultCompany"
      @finished="onUserSelected"
    />
    <EditUser
      :default-company="defaultCompany"
      is-linked-to-company
      ref="editUser"
      @finished="onUserEdited"
    />
    <!--
      The company of the user is bound with the company selected
    -->

    <SelectCompany
      ref="selectCompany"
      :companyType="type"
      @finished="onCompanySelected"
    />
    <EditCompany
      ref="editCompany"
      @finished="onCompanyEdited"
    />
  </div>
</template>

<script>


const setCaretPos = (elem, caretPos) => {
    console.debug('setCaretPos', elem, caretPos)
    if (elem != null) {
      if (elem.createTextRange) {
        let range = elem.createTextRange()
        range.move('character', caretPos)
        range.select()
      }
      else {
        if (elem.selectionStart) {
          elem.focus()
          elem.setSelectionRange(caretPos, caretPos)
        }
        else {
          elem.focus()
        }
      }
    }
  }

import SelectUser from '../Users/SelectUser'
import SelectCompany from '../Companies/SelectCompany'
import EditUser from '../Users/EditUser'
import EditCompany from '../Companies/EditCompany'
import rfdc from 'rfdc'
import { availableCompanyTypes } from '../../utils/constants'

export default {
  components: {
    SelectUser, EditUser, EditCompany, SelectCompany,
  },
  props: {
    type: {
      type: String,
      default: 'finalClient'
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      company: { id: null },
      contact: { id: null },
    }
  },
  computed: {
    companyFormLabel () {
      switch (this.type) {
        case 'finalClient':
          return "Maître d'ouvrage"
        case 'ascensorist':
          return 'Ascensoriste'
        case 'amo':
          return "Assistant maître d'ouvrage"
      }
      throw 'Undefined company form label'
    },

    contactFormLabel () {
      return 'Contact (' + this.companyFormLabel + ')'
    },

    contactName () {
      if (!this.contact || !this.contact.id) {
        return ''
      }
      const user = this.contact
      let name = ''
      if (user.firstName) name += user.firstName
      if (user.lastName) name += ' ' + user.lastName
      name += name.length > 0 ? ' (' + user.email + ')' : user.email
      console.debug(name)
      return name
    },
    companyName () {
      if (!this.company || !this.company.id) {
        return ''
      }
      return this.company.name
    },
    defaultCompany () {
      if (!this.company || !this.company.id) {
        return null
      }
      return this.company
    }
  },
  watch: {
    contactName () {
      this.$nextTick(() => {
        setCaretPos(this.$refs.contactTextField.$el.getElementsByTagName('input')[0], 0)
      })
    }
  },
  methods: {

    openUserSelect() { this.$refs.selectUser.open() },
    removeContact() { this.contact = { id: null } },
    onUserSelected(user) {
      this.contact = user
    },
    onUserEdited(user) { this.contact = user },

    openEditUser(user) {
      this.$refs.editUser.open(user)
    },

    openCompanySelect() { this.$refs.selectCompany.open() },
    removeCompany() { this.company = { id: null } },
    onCompanySelected(company) { this.company = company },
    onCompanyEdited(company) { this.company = company },

    openEditCompany(company = null) {
      this.$refs.editCompany.open(company, this.type)
    },

    getContact() {
      if (!this.contact || !this.contact.id) {
        return null
      }
      return this.contact
    },

    getCompany() {
      if (!this.company || !this.company.id) {
        return null
      }
      return this.company
    },

    load(site) {
      let company = site[this.type]
      let contact = site[this.type + 'Contact']
      if (company && company.id) {
        this.company = company
      }
      if (contact && contact.id) {
        this.contact = contact
      }
    },

    contributeToFormData(base) {
      let formData = base
      if (!this.company.id) {
        formData[this.type] = null
      } else {
        formData[this.type] = '/api/companies/' + this.company.id
      }
      if (!this.contact.id) {
        formData[this.type + 'Contact'] = null
      } else {
        formData[this.type + 'Contact'] = '/api/users/' + this.contact.id
      }
      return formData
    }

  }
}

</script>
