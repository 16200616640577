var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sites,"loading":_vm.tableLoading,"options":_vm.options,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event},"dblclick:row":_vm.onClickedRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-layout',{staticStyle:{"max-width":"300px"}},[_c('v-text-field',{ref:"searchField",attrs:{"hide-details":"","label":"Recherche","clearable":"","clear-icon":"mdi-close"},on:{"click:clear":function($event){_vm.query = ''}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"outlined":"","small":"","icon":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.fetchData()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-refresh")])],1),(_vm.$store.state.auth.user.isAdmin)?_c('v-btn',{staticClass:"mb-2 ml-2",attrs:{"dark":"","color":"accent"},on:{"click":function($event){return _vm.$refs.editForm.open()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.smAndDown ? 'Ajouter' : 'Ajouter un chantier')+" ")],1):_vm._e()],1)]},proxy:true},{key:"item.wasteReceptionDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.wasteReceptionDate,'date'))+" ")]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("truncate")(_vm._f("formatAddress")(item.address)))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"secondary"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.openItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Voir")])]),(_vm.$store.state.auth.user.isAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"info"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.$refs.editForm.open(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifier")])]):_vm._e(),(_vm.$store.state.auth.user.isAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":"","color":"error"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.$refs.removeForm.open(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer")])]):_vm._e()]}}],null,true)}),_c('EditSite',{ref:"editForm",attrs:{"default-company":_vm.company},on:{"finished":_vm.fetchData}}),_c('RemoveItem',{ref:"removeForm",attrs:{"resource":"sites","title":"ce site"},on:{"finished":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }